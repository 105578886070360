export const mediaBreakpointsResolver = {
  xs: 480,
  sm: 560,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1440,
  "3xl": 1920,
} as const;

export const stringifiedMediaBreakpointsResolver = {
  xs: "480px",
  sm: "560px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1440px",
  "3xl": "1920px",
} as const;
